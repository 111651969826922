
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop, Watch} from 'vue-property-decorator';

    import {
        IOrganization,
        ILocation,
    } from '@/types';

    import {
        addressHelper,
    } from '@/helpers';

    declare let H: Window | any;

    @Component
    export default class HereMap extends Vue {
        public platform!: any;
        public map!: any;
        public markersGroup: any;

        @Prop()
        public data!: any[];

        @Prop()
        public sticky!: boolean;

        @Prop()
        public hovering!: any;

        @Watch('hovering')
        public onHoveringChange(val: any, oldVal: any) {
            if (this.markersGroup===undefined) return;
            this.markersGroup.forEach((marker: any) => {
                if (val) {
                    if (marker.getData().id === val.id) {
                        marker.setIcon(new H.map.DomIcon('<i class="mdi mdi-map-marker here-map-marker hover"></i>'));
                    }
                }

                if (oldVal) {
                    if (marker.getData().id === oldVal.id) {
                        marker.setIcon(new H.map.DomIcon('<i class="mdi mdi-map-marker here-map-marker"></i>'));
                    }
                }
            });
        }

        @Watch('data')
        private addMarkers() {
            if (this.markersGroup===undefined) return;
            // Remove all existing markers
            this.markersGroup.removeAll();

            this.data.forEach((marker: IOrganization) => {
                const icon = new H.map.DomIcon('<i class="mdi mdi-map-marker here-map-marker"></i>');
                const mapMarker = new H.map.DomMarker(
                    {
                        lat: (marker.location as ILocation).latitude,
                        lng: (marker.location as ILocation).longitude,
                    },
                    {
                        data: marker,
                        icon,
                    },
                );

                this.markersGroup.addObject(mapMarker);
            });

            if (this.data.length > 1) {
                this.map.getViewModel().setLookAtData({
                    bounds: this.markersGroup.getBoundingBox(),
                    zoom: 10,
                });
            } else if (this.data.length === 1) {
                this.map.setCenter({lat: this.data[0].location.latitude, lng: this.data[0].location.longitude});
                this.map.setZoom(10);
            }
        }

        private created() {
            this.platform = new H.service.Platform({
                apikey: process.env.VUE_APP_HERE_API_KEY,
                useHTTPS: true,
            });
        }

        private mounted() {
            const defaultLayers = this.platform.createDefaultLayers();

            this.map = new H.Map(
                this.$refs.map,
                defaultLayers.vector.normal.map,
                {
                    zoom: 10,
                    pixelRatio: window.devicePixelRatio || 1,
                    center: this.data.length === 1 ? {
                        lat: (this.data[0].location as ILocation).latitude,
                        lng: (this.data[0].location as ILocation).longitude,
                    } : {
                        lat: 48.8588536,
                        lng: 2.3120408,
                    },
                },
            );
            window.addEventListener('resize', () => this.map.getViewPort().resize());

            const ui = new H.ui.UI(this.map, {locale:  'fr-FR'});
            const mapEvents = new H.mapevents.MapEvents(this.map);
            const behavior = new H.mapevents.Behavior(mapEvents);

            this.markersGroup = new H.map.Group();

            this.markersGroup.addEventListener('tap', (evt: any) => {
                if (this.$vuetify.breakpoint.mdAndUp) {
                    return this.$emit('marker-selected', evt.target.getData());
                }

                const organization = evt.target.getData();
                const organizationAddress = addressHelper.getHtmlAddress(organization);

                const bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
                    content: `<div class="map-bubble">
                        <div class="font-weight-bold subtitle-1">
                            ${organization.name}
                        </div>
                        <div class="mb-2 subtitle-2">
                            ${organization.status === 'enabled' ? organization.organization_type.name : 'Établissement vétérinaire'}
                        </div>
                        <div class="mb-2 body-2">
                            ${organizationAddress}<br>
                        </div>
                        <div class="text-center">
                            <a
                                class="
                                    v-btn
                                    v-btn--contained
                                    theme--light
                                    v-size--default
                                    primary
                                    button-slot
                                    font-weight-bold
                                "
                                href="${organization.slug}"
                            >
                                Voir plus
                            </a>
                        </div>
                    </div>`,
                });

                ui.addBubble(bubble);
            }, false);

            this.map.addObject(this.markersGroup);
            this.addMarkers();
        }
    }
