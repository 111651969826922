
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Route, RawLocation, NavigationGuardNext} from 'vue-router';
    import {Watch, Ref} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';

    import Openings from '@/components/Openings.vue';
    import SearchFilters from '@/components/SearchFilters.vue';
    import HereMap from '@/components/HereMap.vue';

    import {
      IClient,
        IListItem,
        IOrganization,
        IPlaceSuggestion,
        IReason,
        ISpecies,
        IRangeOption,
        SearchRange,
        SearchSort,
    } from '@/types';

    import {
        addressHelper,
    } from '@/helpers';

    import {AnimalModule, CalendarModule, SearchModule} from '@/store/modules';

    const calendarNamespace = namespace('calendar');
    const searchNamespace = namespace('search');
    const accountNamespace = namespace('account');

    @Component<ViewSearch>({
        components: {
            SearchFilters,
            Openings,
            HereMap,
        },
        beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
            if (to.name === 'search') {
                this.parseQuery(to, from);
            } else {
                document.documentElement.style.overflow = 'auto';
            }

            next();
        },
    })
    export default class ViewSearch extends Vue {
        public hoveringResult: any = null;
        public hover: boolean = false;
        public showMap: boolean = false;
        public showFilters: boolean = false;
        public loading: boolean = true;
        public sortValues: IListItem[] = [];

        public rangeOptionsItm: IRangeOption[] = [
            {label: 'N\'importe quel jour', value: 'any'},
            {label: 'Aujourd\'hui', value: 'today'},
            {label: 'Les 3 prochains jours', value: 'next_3_days'},
        ];

        public rangeOptionsSel: IRangeOption = this.rangeOptionsItm[0];

        public skeletonTypes = {
            'left-column': 'avatar, button',
            'center-column': 'heading,text@4',
            'right-column': 'openings-header,divider,openings-body,divider,openings-footer',
            'openings-header': 'heading@3',
            'openings-body': 'openings-row@4',
            'openings-row': 'button@3',
            'openings-footer': 'text',
            'result-card': 'left-column,center-column,right-column',
        };

        @searchNamespace.Getter('searchedReason')
        public searchedReason!: IReason|null;

        @searchNamespace.Getter('searchedSpecies')
        public searchedSpecies!: ISpecies|null;

        @searchNamespace.Getter('searchedRange')
        public searchedRange!: SearchRange|null;

        @searchNamespace.Getter('searchedSort')
        public searchedSort!: SearchSort|null;

        @searchNamespace.Getter('searchedPage')
        public searchedPage!: number;

        @searchNamespace.Getter('searchedPerPage')
        public searchedPerPage!: number;

        @searchNamespace.Getter('searchedResultsCount')
        public searchedResultsCount!: number;

        @searchNamespace.Getter('searchedLocation')
        public searchedLocation!: IPlaceSuggestion|null;

        @calendarNamespace.Getter('publicReasonsList')
        public publicReasonsList!: IReason[];

        @searchNamespace.Getter('searchResults')
        public results!: IOrganization[];

        @accountNamespace.Getter('loggedClient')
        public loggedClient!: IClient;

        @Ref('mapview') public button!: HTMLButtonElement;

        get isMdAndUp() {
            return this.$vuetify.breakpoint.mdAndUp;
        }

        get reason(): IReason|null {
            return this.searchedReason;
        }

        set reason(reason: IReason|null) {
            getModule(SearchModule, this.$store).setReason(reason);
            getModule(SearchModule, this.$store).setPage(1);
        }

        get range(): SearchRange|null {
            return this.searchedRange;
        }

        set range(range: SearchRange|null) {
            getModule(SearchModule, this.$store).setRange(range);
            getModule(SearchModule, this.$store).setPage(1);
        }

        get sort(): SearchSort|null {
            return this.searchedSort;
        }

        set sort(sort: SearchSort|null) {
            getModule(SearchModule, this.$store).setSort(sort);
            getModule(SearchModule, this.$store).setPage(1);
        }

        get page(): number {
            return this.searchedPage;
        }

        set page(page: number) {
            getModule(SearchModule, this.$store).setPage(page);
        }

        get perPage(): number {
            return this.searchedPerPage;
        }

        set perPage(perPage: number) {
            getModule(SearchModule, this.$store).setPerPage(perPage);
            getModule(SearchModule, this.$store).setPage(1);
        }

        get pagesCount(): number {
            return Math.ceil(this.searchedResultsCount / this.perPage);
        }

        public handleChangeRange() {
            this.range = (this.rangeOptionsSel.value as SearchRange) || null;
            getModule(SearchModule, this.$store).setRange(this.range);
            getModule(SearchModule, this.$store).setPage(1);
        }

        public markerSelected(organization: IOrganization) {
            const element = (this.$refs[`organization-${organization.id}`] as any[])[0] as Vue;

            if (element) {
                this.$vuetify.goTo(element);
                element.$el.classList.add('selected');
                setTimeout(() => element.$el.classList.remove('selected'), 2000);
            }
        }

        public gotToMap() {
            window.scrollTo({ top: 0, behavior: 'smooth'});
        }

        public getOrganizationAddress(organization: IOrganization) {
            return addressHelper.getHtmlAddress(organization);
        }

        @Watch('isMdAndUp')
        private onBreakPointChange(val: boolean) {
            if (val) {
                document.documentElement.style.overflow = 'auto';
            }
        }

        private handleHash(hash: string|null) {
            this.showMap = hash === '#map';

            if (this.showMap) {
                this.$vuetify.goTo(0);
            }

            document.documentElement.style.overflow = 'auto';

        }

        private async setAddressFromQuery(route: Route): Promise<IPlaceSuggestion|null> {
            const searchModule = getModule(SearchModule, this.$store);
            const latitude = parseFloat(route.query.latitude as string);
            const longitude = parseFloat(route.query.longitude as string);

            if (latitude && longitude) {
                return searchModule
                    .setLocationFromCoords({
                        latitude,
                        longitude,
                    })
                ;
            }

            return new Promise<null>((resolve) => {
                searchModule.setLocation(null);
                resolve(null);
            });
        }

        private async setAnimalTypeFromQuery(route: Route): Promise<ISpecies|null> {
            const searchModule = getModule(SearchModule, this.$store);
            const animalModule = getModule(AnimalModule, this.$store);
            const name = route.query.species as string;

            if (!name) {
                return new Promise<null>((resolve) => {
                    searchModule.setSpecies(null);
                    resolve(null);
                });
            }

            return new Promise<ISpecies|null>((resolve, reject) => {
                return animalModule
                    .computeSpeciesByTypeList()
                    .then(() => {
                        searchModule.setSpeciesFromName(name);
                        resolve(searchModule.searchedSpecies);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                ;
            });
        }

        private async setReasonFromQuery(route: Route): Promise<IReason|null> {
            const searchModule = getModule(SearchModule, this.$store);
            const calendarModule = getModule(CalendarModule, this.$store);
            const name = route.query.reason as string;

            return new Promise<IReason|null>((resolve, reject) => {
                return calendarModule
                    .fetchPublicReasons()
                    .then(() => {
                        if (name) {
                            searchModule.setReasonFromName(name);
                        }

                        this.reason = searchModule.searchedReason;
                        resolve(this.reason);
                    })
                    .catch((error) => reject(error))
                ;
            });
        }

        private setSortFromQuery(route: Route) {
            this.sort = route.query.sort_by ? route.query.sort_by as SearchSort : 'relevance';
        }

        private setRangeFromQuery(route: Route) {
            this.range = route.query.range ? route.query.range as SearchRange : 'any';
        }

        private setPageFromQuery(route: Route) {
            this.page = route.query.page ? parseInt(route.query.page as string, 10) : 1;
        }

        private setPerPageFromQuery(route: Route) {
            this.perPage = route.query.sort ? parseInt(route.query.sort as string, 10) : 10;
        }

        private parseQuery(route: Route, fromRoute?: Route) {
            const searchModule = getModule(SearchModule, this.$store);

            Promise
                .all([
                    this.setAddressFromQuery(route),
                    this.setAnimalTypeFromQuery(route),
                    this.setReasonFromQuery(route),
                ])
                .then(() => {
                    this.handleHash(route.hash);

                    this.setSortFromQuery(route);
                    this.setRangeFromQuery(route);
                    this.setPerPageFromQuery(route);
                    this.setPageFromQuery(route);

                    const rawSearchRoute = searchModule.searchRoute;

                    if (route.hash) {
                        rawSearchRoute.hash = route.hash;
                    } else {
                        delete rawSearchRoute.hash;
                    }

                    const toRoute = this.$router.resolve(searchModule.searchRoute);

                    if (route.fullPath !== toRoute.resolved.fullPath) {
                        this.$router.replace(searchModule.searchRoute);
                    } else {
                        // Search only if route actually changed, not just hash
                        if (!fromRoute || !this.compareRoutesWithoutHash(fromRoute, route)) {
                            this.loading = true;

                            searchModule
                                .doSearch()
                                .then((response) => {
                                    searchModule.setLocationFromCoords(response.location);
                                })
                                .finally(() => this.loading = false)
                            ;
                        }
                    }
                })
            ;
        }

        private compareRoutesWithoutHash(a: Route, b: Route) {
            const aCopy = {...a};
            delete (aCopy as any).hash;
            const aFixed = this.$router.resolve(aCopy as RawLocation);

            const bCopy = {...b};
            delete (bCopy as any).hash;
            const bFixed = this.$router.resolve(bCopy as RawLocation);

            return aFixed.resolved.fullPath === bFixed.resolved.fullPath;
        }

        private updateUrl() {
            this.$router.push(getModule(SearchModule, this.$store).searchRoute);
        }

        private mounted() {
            this.sortValues = this.$api.search.getSortValues();
            this.parseQuery(this.$route);
            this.updateUrl();
        }
    }
